<template>
  <b-modal
    id="preConfirmSchedule"
    lazy
    hide-header
    hide-footer
    size="md"
    @hide="handleClose"
    @close="handleClose"
  >

    <b-row class="d-flex justify-content-center mt-3">
      <b-col md="6">
        {{ $i18n.t('modalPreConfirmSchedule.title') }}
      </b-col>

      <b-col md="2" class="alert-icon">
        <i class="fa fa-exclamation-triangle" />
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-3 ml-2 mr-2">
      <b-col md="8" class="schedule-box">
        <div v-for="(item, index) in itemsList" :key="index">
          <p class="mt-3 mb-2"><strong>{{ $i18n.t('modalPreConfirmSchedule.card.type') }}</strong> {{ $i18n.t(`modalPreConfirmSchedule.kind.${item.type}`) }}</p>
          <p class="mb-2"><strong>{{ $i18n.t('modalPreConfirmSchedule.card.reason') }}</strong> {{ item.reason | format_schedule('type') }}</p>
          <p class="mb-2"><strong>{{ $i18n.t('modalPreConfirmSchedule.card.date') }}</strong> {{ item.date | format_date('DD/MM/YYYY - HH:mm') }}</p>
          <p class="mb-2"><strong>{{ $i18n.t('modalPreConfirmSchedule.card.place') }}</strong> {{ item.place }}</p>
          <p class="mb-2"><strong>{{ $i18n.t('modalPreConfirmSchedule.card.scheduledBy') }}</strong> {{ item.scheduledBy }}</p>
          <p class="mb-3"><strong>{{ $i18n.t('modalPreConfirmSchedule.card.comments') }}</strong> {{ item.comments }}</p>

          <hr v-if="itemsCount > 1 && index === 0" class="schedule-separator">
        </div>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-3 mb-0">
      <b-col md="8" class="text-left mb-0">
        <p class="mb-0">{{ $i18n.t('modalPreConfirmSchedule.question') }}</p>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-3 mb-3">
      <b-col md="4" class="text-left">
        <b-button variant="btn btn-dark" @click="handleClose">
          {{$i18n.t('modalPreConfirmSchedule.buttons.cancel')}}
        </b-button>
      </b-col>

      <b-col md="4" class="text-right">
        <b-button variant="success" @click="confirmSchedule" >
          {{$i18n.t('modalPreConfirmSchedule.buttons.confirm')}}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'pre-confirm-schedule',
  components: {},
  data() {
    return {
      itemsList: []
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    itemsCount() {
      return this.items.length
    }
  },
  methods: {
    handleClose() {
      this.$emit('returnPreConfirmSchedule', 'cancel');
    },

    confirmSchedule() {
      this.$emit('returnPreConfirmSchedule', 'confirmed');
    }
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(value) {
        this.itemsList = value;
      }
    }
  }
};
</script>

<style scoped>
.alert-icon {
  font-size: 2em;
  color: #ff820f;
}
.schedule-box {
  background-color: #e0e0e0;
}
.schedule-separator {
  height: 1%;
  background-color: #1c1c1c;
}
button {
  width: 100%;
}
</style>
