var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "preConfirmSchedule",
        lazy: "",
        "hide-header": "",
        "hide-footer": "",
        size: "md",
      },
      on: { hide: _vm.handleClose, close: _vm.handleClose },
    },
    [
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-center mt-3" },
        [
          _c("b-col", { attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.$i18n.t("modalPreConfirmSchedule.title")) + " "
            ),
          ]),
          _c("b-col", { staticClass: "alert-icon", attrs: { md: "2" } }, [
            _c("i", { staticClass: "fa fa-exclamation-triangle" }),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-center mt-3 ml-2 mr-2" },
        [
          _c(
            "b-col",
            { staticClass: "schedule-box", attrs: { md: "8" } },
            _vm._l(_vm.itemsList, function (item, index) {
              return _c("div", { key: index }, [
                _c("p", { staticClass: "mt-3 mb-2" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$i18n.t("modalPreConfirmSchedule.card.type"))
                    ),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$i18n.t(`modalPreConfirmSchedule.kind.${item.type}`)
                      )
                  ),
                ]),
                _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$i18n.t("modalPreConfirmSchedule.card.reason"))
                    ),
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm._f("format_schedule")(item.reason, "type"))
                  ),
                ]),
                _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$i18n.t("modalPreConfirmSchedule.card.date"))
                    ),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("format_date")(item.date, "DD/MM/YYYY - HH:mm")
                      )
                  ),
                ]),
                _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$i18n.t("modalPreConfirmSchedule.card.place"))
                    ),
                  ]),
                  _vm._v(" " + _vm._s(item.place)),
                ]),
                _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$i18n.t("modalPreConfirmSchedule.card.scheduledBy")
                      )
                    ),
                  ]),
                  _vm._v(" " + _vm._s(item.scheduledBy)),
                ]),
                _c("p", { staticClass: "mb-3" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$i18n.t("modalPreConfirmSchedule.card.comments")
                      )
                    ),
                  ]),
                  _vm._v(" " + _vm._s(item.comments)),
                ]),
                _vm.itemsCount > 1 && index === 0
                  ? _c("hr", { staticClass: "schedule-separator" })
                  : _vm._e(),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-center mt-3 mb-0" },
        [
          _c("b-col", { staticClass: "text-left mb-0", attrs: { md: "8" } }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.$i18n.t("modalPreConfirmSchedule.question"))),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-center mt-3 mb-3" },
        [
          _c(
            "b-col",
            { staticClass: "text-left", attrs: { md: "4" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "btn btn-dark" },
                  on: { click: _vm.handleClose },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$i18n.t("modalPreConfirmSchedule.buttons.cancel")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "4" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success" },
                  on: { click: _vm.confirmSchedule },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$i18n.t("modalPreConfirmSchedule.buttons.confirm")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }