<template>
  <b-modal id="inspectionCarSchedule" lazy hide-footer size="lg" :title="modal.title" @hide="handleClose" @close="handleClose">
    <driver-header :id="$route.params.id" :reset-profile="false" />

    <b-row class="card-wrapper d-flex justify-content-end mt-3">
      <b-col md="12" class="mt-sm-3 mt-md-0">
        <b-form-group :label="$i18n.t('drivers.labels.scheduleType')">
          <multiselect
            label="text"
            :placeholder="$t('drivers.labels.select')"
            track-by="value"
            :close-on-select="true"
            :disabled="!modal.availableTypeSchedule.length || block_ui"
            :multiple="false"
            :options="modal.availableTypeSchedule"
            :value="getSelected"
            @input="setSelected"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title mr-2">{{ props.option.text }}</span>
                <span v-if="props.option.description" v-b-popover.hover.right="props.option.description" class="option__small"
                  ><i class="fa fa-info-circle"
                /></span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col md="12" class="mt-sm-3 mt-md-0">
        <p>{{ $i18n.t('drivers.labels.reasonSchedule') }}</p>
        <b-form-textarea
          id="textAreaReasonChedule"
          v-model="modal.reasonText"
          rows="3"
          :placeholder="$i18n.t('drivers.labels.typeHere')"
          max-rows="6"
          :disabled="block_ui"
        />
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-3 card-wrapper">
      <b-col md="12">
        <h2>{{ $i18n.t('drivers.titles.inspection') }}</h2>

        <b-row class="d-flex justify-content-center mt-3">
          <b-form-group class="select-wrapper" style="width: 100%; padding: 0 15px" :label="'Condutor'">
            <b-form-select
              v-model="modal.selectedConductor"
              :options="modal.availableConductors"
              :disabled="!modal.availableConductors.length || block_ui"
              data-test="select-conductors"
            >
              <template slot="first">
                <option :value="null" disabled>
                  {{ $i18n.t('drivers.labels.select') }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-row>

        <b-row class="d-flex justify-content-center mt-3">
          <b-col md="6" class="mt-sm-3 mt-md-0">
            <select-places
              :place-title="$i18n.t('drivers.labels.placesDelivery')"
              :place-text="$i18n.t('drivers.labels.selectPlaces')"
              :type="{ delivery: true }"
              :disabled="block_ui"
              @eventPlaces="onEventPlaces($event)"
            />
          </b-col>

          <b-col md="6" class="mt-sm-3 mt-md-0">
            <p>{{ $i18n.t('drivers.labels.dateDelivery') }}</p>
            <datepicker v-model="modal.schedule_date" v-bind="date_picker_props" :lang="getLanguage" :disabled="block_ui" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-end mt-3">
      <b-col md="12" class="text-right">
        <b-button :disabled="block_ui" variant="btn btn-secondary" style="margin-right: 50px" @click="handleClose">
          {{ $i18n.t('drivers.buttons.cancel') }}
        </b-button>

        <b-button :disabled="!valid || block_ui" variant="success" @click="preConfirmSchedule">
          {{ $i18n.t('drivers.buttons.schedule') }}
        </b-button>
      </b-col>
    </b-row>

    <modal-pre-confirm-schedule :items="preConfirmItems" @returnPreConfirmSchedule="$_returnPreConfirmSchedule($event)" />
  </b-modal>
</template>

<script>
import DriverHeader from '@components/drivers/header';
import SelectPlaces from '@components/shared/SelectPlaces';
import { currentLanguage } from '@utils/language';
import { availableTypeInspectionSchedule } from '@utils/availableTypeSchedule';
import { availableConductors } from '@utils/availableConductors';
import SchedulesInspection from '@graphql/schedules/mutations/create-schedule-inspection.gql';
import PreConfirmSchedule from '@graphql/schedules/queries/pre-confirm-schedule.gql';
import ModalPreConfirmSchedule from '@components/drivers/profile/modals/PreConfirmSchedule';
import { mapGetters } from 'vuex';
import { isMexico, isBrazil } from '@utils/helper-region';
import { COUNTRIES } from '@src/enums/regions';

export default {
  name: 'InspectionCarSchedule',

  components: {
    DriverHeader,
    SelectPlaces,
    ModalPreConfirmSchedule,
  },

  data() {
    return {
      modal: {
        title: this.$i18n.t('drivers.titles.scheduleInspection'),
        selectedTypeSchedule: null,
        selectedConductor: null,
        schedule_date: null,
        reasonText: null,
        place: null,
        availableTypeSchedule: this.getAvalibleTypeSchedulePerRegion(),
        availableConductors: availableConductors,
      },
      valid: false,
      block_ui: false,
      preConfirmItems: [],
    };
  },

  computed: {
    ...mapGetters('env', {
      envSchedules: 'schedules',
    }),

    getLanguage() {
      return currentLanguage;
    },

    date_picker_props() {
      return {
        type: 'datetime',
        timePickerOptions: {
          start: '05:30',
          step: '00:15',
          end: '22:30',
        },
        shortcuts: false,
        clearable: false,
        editable: false,
        notBefore: this.today,
        notAfter: this.week,
        width: 'auto',
        format: 'DD/MM/YYYY HH:mm',
      };
    },

    today() {
      if (this.$moment().format('HH') > 18) {
        // If date > 18 send to another day
        return this.$moment().add(8, 'hours').format();
      }
      return this.$moment().format();
    },

    week() {
      return this.$moment().add(this.envSchedules.inspection, 'weeks').format();
    },

    is_valid_schedule_date() {
      return !!this.modal.schedule_date && this.$moment(this.modal.schedule_date).isValid(true);
    },
    getSelected() {
      const selected = this.modal.selectedTypeSchedule;
      const available = this.modal.availableTypeSchedule;

      return selected && available.length ? available.find(item => item.value === selected) : null;
    },
  },

  watch: {
    modal: {
      deep: true,
      handler(value) {
        this.valid = false;
        const scheduleTypeValid = value.selectedTypeSchedule !== null && value.selectedTypeSchedule !== '';
        const scheduleDateValid = this.is_valid_schedule_date;
        const reasonTextValid = value.reasonText !== null && value.reasonText !== '';
        const placeSelectValid = value.place !== null && value.place !== '';
        const selectedConductorValid = value.selectedConductor !== null && value.selectedConductor !== '';

        if (scheduleTypeValid && scheduleDateValid && reasonTextValid && placeSelectValid && selectedConductorValid) {
          this.valid = true;
        }
      },
    },
  },

  methods: {
    setSelected({ value }) {
      this.modal.selectedTypeSchedule = value;
    },
    handleClose() {
      this.modal.selectedTypeSchedule = null;
      this.modal.schedule_date = null;
      this.modal.reasonText = null;
      this.modal.place = null;
      this.modal.selectedConductor = null;

      this.$root.$emit('bv::hide::modal', 'inspectionCarSchedule');
    },

    getAvalibleTypeSchedulePerRegion() {
      const region = isBrazil() ? COUNTRIES.BR : COUNTRIES.MX;

      return availableTypeInspectionSchedule.filter(type => type.ignoreRegions != region);
    },

    async preConfirmSchedule() {
      this.block_ui = true;

      await this.$apollo
        .query({
          query: PreConfirmSchedule,
          variables: {
            filters: {
              where: {
                driver: this.$route.params.id,
                status: 'SCHEDULED',
                kind: ['INSPECTION'],
                type: this.modal.selectedTypeSchedule,
              },
              order: [['created_at', 'ASC']],
            },
          },
        })
        .then(res => {
          const schedules = res.data.schedules;

          if (schedules && schedules.items && schedules.total > 0) {
            let list = schedules.items.map(item => {
              return {
                type: item.kind,
                reason: item.type,
                date: item.scheduled_at,
                place: item.place.name,
                scheduledBy: item.assigned_by,
                comments: item.comments,
              };
            });

            this.preConfirmItems = list;

            this.$root.$emit('bv::show::modal', 'preConfirmSchedule');
          } else {
            this.confirmSchedule();
          }
        })
        .catch(err => {
          this.$swal({
            type: 'error',
            title: `Ocorreu um erro na sua solicitação, tente novamente`,
            text: err,
          });
        });
    },

    $_returnPreConfirmSchedule(event) {
      this.$root.$emit('bv::hide::modal', 'preConfirmSchedule');

      if (event === 'cancel') this.block_ui = false;

      if (event === 'confirmed') this.confirmSchedule();
    },

    confirmSchedule() {
      this.block_ui = true;

      this.$apollo
        .mutate({
          mutation: SchedulesInspection,
          variables: {
            input: {
              driver: this.$route.params.id,
              type: this.modal.selectedTypeSchedule,
              place: this.modal.place,
              comments: this.modal.reasonText,
              scheduled_at: this.modal.schedule_date,
              forceSchedule: true,
              metadata: {
                conductor: this.modal.selectedConductor,
              },
            },
          },
        })
        .then(result => {
          if (result.data.createScheduleInspection.id) {
            const configSwalSuccess = {
              type: 'success',
              title: this.$i18n.t('modalWaitingDelivery.texts.inspectionWasSchedule'),
              timer: 2000,
              text: '',
              showConfirmButton: false,
              showCancelButton: false,
              onAfterClose: async () => {
                await this.handleClose();
                await window.location.reload();
              },
            };

            this.$swal(configSwalSuccess);

            this.block_ui = false;
          }
        })
        .catch(error => {
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalWaitingDelivery.texts.couldNotPossibleScheduleThisDelivery'),
            text: error,
          };

          this.$swal(configSwalFail);

          this.block_ui = false;
        });
    },

    onEventPlaces($event) {
      this.modal.place = $event.value;
    },
  },
};
</script>

<style scoped>
.card-wrapper {
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
  margin: 0px;
  padding: 15px 0;
}

button {
  min-width: 135px;
  margin-bottom: 50px;
}
</style>
